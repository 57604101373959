import React from "react";
import './styles/app.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import {NextUIProvider} from "@nextui-org/react";
import Header from "./components/website/header";
import "@fontsource/ibm-plex-sans";
import "@fontsource/ibm-plex-mono";
import { MaterialSymbol } from 'react-material-symbols';
import 'react-material-symbols/rounded';
import 'react-material-symbols/outlined';
import Homepage from "./pages/Homepage";
import LibriPage from "./pages/Libri";
import LoginPage from "./pages/Login";
import AdozioniPage from "./pages/Adozioni";
import RegistratiPage from "./pages/Registrati";
import { AccountProvider } from "./contexts/AccountContext";
import ProfiloPage from "./pages/Profilo";
import RecuperaPasswordPage from "./pages/RecuperaPasswordPage";
import RecuperoPasswordPage from "./pages/RecuperoPasswordPage";
import CambiaPasswordPage from "./pages/CambiaPasswordPage";
import AttivaAccountPage from "./pages/AttivaAccount";

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

function App() {
  return (
    <NextUIProvider>
      <AccountProvider>
        <Router>
          <Header />
          <Routes>
            <Route path="/" exact element={<><Homepage /><ScrollToTop /></>} />
            <Route path="/libri" exact element={<><LibriPage /><ScrollToTop /></>} />
            <Route path="/adozioni" exact element={<><AdozioniPage /><ScrollToTop /></>} />
            <Route path="/login" exact element={<><LoginPage /><ScrollToTop /></>} />
            <Route path="/registrati" exact element={<><RegistratiPage /><ScrollToTop /></>} />
            <Route path="/password-dimenticata" exact element={<><RecuperaPasswordPage /><ScrollToTop /></>} />
            <Route path="/recupera-password" exact element={<><RecuperoPasswordPage /><ScrollToTop /></>} />
            <Route path="/profilo" exact element={<><ProfiloPage /><ScrollToTop /></>} />
            <Route path="/modifica-password" exact element={<><CambiaPasswordPage /><ScrollToTop /></>} />
            <Route path="/verifica" exact element={<><AttivaAccountPage /><ScrollToTop /></>} />
            {/*<Route path="*" exact element={<><Pagina404 /><ScrollToTop /></>} />*/}
          </Routes>
          <div className="h-36 block md:hidden"></div>
        </Router>
      </AccountProvider>
    </NextUIProvider>
  );
}

export default App;