import React from "react";
import { API_URL } from "../config";
import { Checkbox, Input, Dropdown, DropdownTrigger, DropdownMenu, DropdownSection, DropdownItem, Button, CircularProgress } from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";
import { MaterialSymbol } from 'react-material-symbols';
import { useAccount } from "../contexts/AccountContext";

const LoginPage = () => {
    const navigate = useNavigate();
    const { ChangeLogged } = useAccount();
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [remember, setRemember] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    const login = () => {
        setLoading(true);
        var data=new FormData();
        data.append('email', email);
        data.append('password', password);
        fetch(API_URL + 'login', {
            method: 'POST',
            body: data,
            credentials: 'include',
            redirect: 'follow',
            headers: {
                'Accept': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.loggato) {
                    ChangeLogged();
                    navigate('/');
                } else {
                    if(data.message=="LOGIN_ERROR_WRONG_CREDENTIALS") {
                        setError("Email o password errati");
                    } else if(data.message=="LOGIN_ERROR_GENERIC") {
                        setError("Errore generico");
                    } else if(data.message=="LOGIN_ERROR_MISSING_FIELDS") {
                        setError("Compila tutti i campi");
                    } else if(data.message=="LOGIN_ERROR_WRONG_METHOD") {
                        setError("Metodo non valido");
                    } else {
                        setError("Errore sconosciuto");
                    }
                    setLoading(false);
                }
            });
    }

    return (
        <div className="container mt-3.5">
            <div className="w-full md:w-1/2 mx-auto">
                <h1 className="text-3xl font-bold mt-4 mb-4">Accedi</h1>
                {error && <p className="bg-red-500 text-white py-2 px-3 mb-2.5 rounded shadow">{error}</p>}
                <Button className="mb-3 w-full" color="primary" size="md" onClick={() => window.location.href="https://mercatinoudsbari.it/api/login-google/"}>Accedi con Google</Button>
                <Input type="email" label="Email" size={"lg"} value={email} onValueChange={setEmail} className="mb-3" />
                <Input type={showPassword ? "text" : "password"} label="Password" size={"lg"} value={password} onValueChange={setPassword} iconRight={showPassword ? <MaterialSymbol icon="visibility_off" size={32} fill grade={-25} />  : <MaterialSymbol icon="visibility" size={32} fill grade={-25} />} onIconRightClick={() => setShowPassword(!showPassword)} className="mb-3"  />
                <Button className="mt-2.5 mb-3 w-full" color="primary" size="lg" onClick={login} disabled={loading}>{loading ? <CircularProgress color="white" aria-label="Loading..." /> : "Accedi"}</Button>
                <p>Non hai un account? <Link to="/registrati" className="text-blue-500 mt-2.5">Registrati</Link></p>
                <Link to="/password-dimenticata" className="text-blue-500 block mt-1">Password dimenticata?</Link>
            </div>
        </div>
    );
}

export default LoginPage;