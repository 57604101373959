import React from 'react';
import { API_URL } from '../../config';
import { ModalContent, ModalHeader, ModalBody, ModalFooter, Listbox, ListboxItem, Button } from "@nextui-org/react";

const ModalVetrina = ({libro, setIsOpen}) => {
    const [libri, setLibri] = React.useState([]);
    React.useEffect(() => {
        fetch(API_URL + 'vetrina?isbn=' + libro.isbn)
            .then(response => response.json())
            .then(data => setLibri(data.libri));
    }, []);
    return (
        <ModalContent>
            <ModalHeader>{libro.titolo}</ModalHeader>
            <ModalBody>
                    <Listbox variant="flat">
                {libri.map((libro) => (
                    <ListboxItem
                    classNames={{"title": "whitespace-normal" }}
                    description={"ID LIBRO: " + libro.id+" - PREZZO: "+libro.prezzo_vendita+"€"}
                    startContent={<div className="flex items-center">
                        {Array.from({ length: libro.stato }).map((_, index) => (<span key={index} className="text-yellow-500 mr-1">⭐️</span>))}</div>}
                  >
                    {libro.titolo}
                  </ListboxItem>
                ))}
                    </Listbox>
            </ModalBody>
            <ModalFooter>
                <Button className='w-full' color="primary" onPress={() => setIsOpen(false)}>CHIUDI</Button>
            </ModalFooter>
        </ModalContent>
    );
}

export default ModalVetrina;