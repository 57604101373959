import { MaterialSymbol } from 'react-material-symbols';
import { Link, NavLink } from 'react-router-dom';
import { useAccount } from '../../contexts/AccountContext';
import { API_URL } from '../../config';

const Header = () => {
    const { account } = useAccount();
    const logout = () => {
        var requestOptions = {
            credentials: 'include',
            method: 'POST',
            redirect: 'follow'
        };
        fetch(API_URL + 'logout/', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(!data.loggato) {
                    window.location.href = "/";
                }
            });
    }
    return (
        <>
        <header className="sticky top-0 z-50 flex justify-between items-center md:py-2 px-4 bg-white shadow-md">
            <Link to="/" className="flex items-center">
                <img src="https://cdn.mercatinoudsbari.it/logo.png" alt="logo" className="h-16 w-16" />
                <h1 className="text-lg md:text-2xl font-semibold text-gray-800">Mercatino del libro usato</h1>
            </Link>
            <div className="hidden md:block flex items-center space-x-2">
                <nav className="inline space-x-4">
                    <NavLink
                    to={"/"}
                    className={({ isActive }) => {
                    const textClass = isActive ? "text-gray-800 font-bold" : "text-gray-800";
                    return `${textClass}`;
                    }}
                    end
                    >
                    Home
                    </NavLink>
                    <NavLink
                    to={"/libri"}
                    className={({ isActive }) => {
                    const textClass = isActive ? "text-gray-800 font-bold" : "text-gray-800";
                    return `${textClass}`;
                    }}
                    end
                    >
                    Libri
                    </NavLink>
                    <NavLink
                    to={"/adozioni"}
                    className={({ isActive }) => {
                    const textClass = isActive ? "text-gray-800 font-bold" : "text-gray-800";
                    return `${textClass}`;
                    }}
                    end
                    >
                    Adozioni
                    </NavLink>
                </nav>
                {account.loggato ? (
                <div className="inline space-x-4 border-solid border-l-2 pl-2">
                    <NavLink
                    to={"/profilo"}
                    className={({ isActive }) => {
                    const textClass = isActive ? "text-gray-800 font-bold" : "text-gray-800";
                    return `${textClass}`;
                    }}
                    end
                    >
                    Profilo
                    </NavLink>
                    <Link onClick={logout} className="bg-blue-500 text-white px-4 py-2 rounded-full">Logout</Link>
                </div>
                ) : (
                    <div className="inline space-x-4 border-solid border-l-2 pl-2">
                        <NavLink
                        to={"/login"}
                        className={({ isActive }) => {
                        const textClass = isActive ? "text-gray-800 font-bold" : "text-gray-800";
                        return `${textClass}`;
                        }}
                        end
                        >
                        Login
                        </NavLink>
                        <Link to="/registrati" className="bg-blue-500 text-white px-4 py-2 rounded-full">Registrati</Link>
                    </div>
                )}
            </div>
        </header>
        <nav className="shadow-inner fixed md:hidden bottom-0 left-0 right-0 grid grid-cols-4 justify-around items-center py-2 px-4 bg-white shadow-md z-50" style={{"--tw-shadow":"0 -4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"}}>
            <NavLink
                to={"/"}
                className={({ isActive }) => {
                const textClass = isActive ? "flex flex-col items-center text-gray-800 font-bold" : "flex flex-col items-center text-gray-800";
                return `${textClass}`;
                }}
                end
                >
                <MaterialSymbol icon="home" size={32} fill grade={-25} />
                Home
            </NavLink>
            <NavLink
                to={"/libri"}
                className={({ isActive }) => {
                const textClass = isActive ? "flex flex-col items-center text-gray-800 font-bold" : "flex flex-col items-center text-gray-800";
                return `${textClass}`;
                }}
                end
                >
                <MaterialSymbol icon="book_3" size={32} fill grade={-25} />
                Libri
            </NavLink>
            <NavLink
                to={"/adozioni"}
                className={({ isActive }) => {
                const textClass = isActive ? "flex flex-col items-center text-gray-800 font-bold" : "flex flex-col items-center text-gray-800";
                return `${textClass}`;
                }}
                end
                >
                <MaterialSymbol icon="school" size={32} fill grade={-25} />
                Adozioni
            </NavLink>
            {account.loggato ? (
                <NavLink
                to={"/profilo"}
                className={({ isActive }) => {
                const textClass = isActive ? "flex flex-col items-center text-gray-800 font-bold" : "flex flex-col items-center text-gray-800";
                return `${textClass}`;
                }}
                end
                >
                <MaterialSymbol icon="account_circle" size={32} fill grade={-25} />
                Profilo
            </NavLink>
            ) : (
                <NavLink
                    to={"/login"}
                    className={({ isActive }) => {
                    const textClass = isActive ? "flex flex-col items-center text-gray-800 font-bold" : "flex flex-col items-center text-gray-800";
                    return `${textClass}`;
                    }}
                    end
                    >
                    <MaterialSymbol icon="account_circle" size={32} fill grade={-25} />
                    Login
                </NavLink>
            )}
        </nav>
        </>
    );
}
export default Header;