import React from "react";
import { API_URL } from "../config";
import { Checkbox, Input, Dropdown, DropdownTrigger, DropdownMenu, DropdownSection, DropdownItem, Button, CircularProgress } from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";
import { MaterialSymbol } from 'react-material-symbols';
import { useAccount } from "../contexts/AccountContext";

const RecuperaPasswordPage = () => {
    const [email, setEmail] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");

    const RecuperaPassword = () => {
        setLoading(true);
        var data=new FormData();
        data.append('email', email);
        fetch(API_URL + 'richiediRecuperoPassword', {
            method: 'POST',
            body: data,
            credentials: 'include',
            redirect: 'follow',
            headers: {
                'Accept': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.requested) {
                    if(data.message=="EMAIL_SENT") {
                        setSuccess("Clicca sul link inviato via mail entro 25 minuti per cambiare la tua password");
                    } else {
                        setSuccess("Clicca sul link inviato via mail entro 25 minuti per cambiare la tua password");
                    }
                    setLoading(false);
                    setError("");
                } else {
                    if(data.message=="EMAIL_NOT_FOUND") {
                        setError("Email non trovata");
                    } else if(data.message=="EMAIL_NOT_SENT") {
                        setError("Si è verificato un problema durante l'invio della mail");
                    } else {
                        setError("Errore generico");
                    }
                    setLoading(false);
                    setSuccess("");
                }
            });
    }

    return (
        <div className="container mt-3.5">
            <div className="w-full md:w-1/2 mx-auto">
                <h1 className="text-3xl font-bold mt-4 mb-4">Recupera password</h1>
                {success && <p className="bg-green-500 text-white py-2 px-3 mb-2.5 rounded shadow">{success}</p>}
                {error && <p className="bg-red-500 text-white py-2 px-3 mb-2.5 rounded shadow">{error}</p>}
                <Input type="email" label="Email" size={"lg"} value={email} onValueChange={setEmail} className="mb-3" />
                <Button className="mt-2.5 mb-3 w-full" color="primary" size="lg" onClick={RecuperaPassword} disabled={loading}>{loading ? <CircularProgress color="white" aria-label="Loading..." /> : "Recupera password"}</Button>
                <Link to="/login" className="text-blue-500 block mt-1">Torna al login</Link>
            </div>
        </div>
    );
}

export default RecuperaPasswordPage;