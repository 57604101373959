import React from "react";

const CardLibroOrizzontale = ({ libro, isOpen, setIsOpen, setLibroCliccato }) => {
    return (
        <>
            <div className="bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition-all">
                {libro.presente_in_vetrina && <span className="bg-yellow-500 text-white px-2 py-1 rounded-full mb-1.5 uppercase hover:shadow active:shadow" onClick={() => { setLibroCliccato(libro);setIsOpen(true); }}>In vetrina</span>}
                <p className="text-gray-600 mb-0.5">{libro.autore}</p>
                <h2 className="text-2xl font-bold mb-2 break-all">{libro.titolo}</h2>
                <p className="text-gray-600 mb-1">Pubblicato da: {libro.casa_editrice}</p>
                <p className="text-gray-600 mb-1">Materia: {libro.materia}</p>
                <p className="text-gray-600 mb-2">ISBN: {libro.isbn}</p>
                {libro.id_libro && <p className="text-gray-600 mb-2">ID: {libro.id_libro}</p>}
                <div className="flex items-center">
                {Array.from({ length: libro.stato }).map((_, index) => (
                    <span key={index} className="text-yellow-500 mr-1">⭐️</span>
                ))}
                </div>
            </div>
        </>
    );
};

export default CardLibroOrizzontale;