import React from "react";
import { API_URL } from "../config";
import { Checkbox, Input, Dropdown, DropdownTrigger, DropdownMenu, DropdownSection, DropdownItem, Button, CircularProgress } from "@nextui-org/react";
import { Link } from "react-router-dom";
import { MaterialSymbol } from 'react-material-symbols';
import { DeprecatedLayoutGroupContext } from "framer-motion";

const RegistratiPage = () => {
    const [nome, setNome] = React.useState("");
    const [cognome, setCognome] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [telefono, setTelefono] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);

    const register = () => {
        setLoading(true);
        var data=new FormData();
        data.append('nome', nome);
        data.append('cognome', cognome);
        data.append('telefono', telefono);
        data.append('email', email);
        data.append('password', password);
        fetch(API_URL + 'registrazione', {
            method: 'POST',
            body: data,
            credentials: 'include',
            redirect: 'follow',
            headers: {
                'Accept': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    if(data.message="SIGNUP_OK_CHECK_MAIL") {
                        setSuccess("Controlla la tua email per confermare l'account");
                    } else if(data.message="SIGNUP_OK_NOT_MAIL") {
                        setSuccess("Registrazione completata, ma si è verificato un errore nell'invio della mail di conferma. Prego contatta l'assistenza.");
                    } else {
                        setSuccess("Registrazione completata");
                    }
                    setLoading(false);
                    setError("");
                } else {
                    if(data.message=="SIGNUP_ERROR_EMPTY_FIELDS") {
                        setError("Compila tutti i campi");
                    } else if(data.message=="SIGNUP_ERROR_GENERIC") {
                        setError("Errore generico");
                    } else if(data.message=="SIGNUP_ERROR_EMAIL_EXISTS") {
                        setError("Email già registrata");
                    } else if(data.message=="SIGNUP_ERROR_WRONG_METHOD") {
                        setError("Metodo non valido");
                    }
                    setLoading(false);
                    setSuccess("");
                }
            });
    }

    return (
        <div className="container mt-3.5">
            <div className="w-full md:w-1/2 mx-auto">
                <h1 className="text-3xl font-bold mt-4 mb-4">Registrati</h1>
                {error && <p className="bg-red-500 text-white py-2 px-3 mb-2.5 rounded shadow">{error}</p>}
                {success && <p className="bg-green-500 text-white py-2 px-3 mb-2.5 rounded shadow">{success}</p>}
                <Button className="mb-3 w-full" color="primary" size="md" onClick={() => window.location.href="https://mercatinoudsbari.ottomanelliandrea.it/api/login-google/"}>Registrati con Google</Button>
                <Input type="text" label="Nome" size={"lg"} value={nome} onValueChange={setNome} className="mb-3" />
                <Input type="text" label="Cognome" size={"lg"} value={cognome} onValueChange={setCognome} className="mb-3" />
                <Input type="email" label="Email" size={"lg"} value={email} onValueChange={setEmail} className="mb-3" description="Sarà il tuo username" />
                <Input type="text" label="Telefono" size={"lg"} value={telefono} onValueChange={setTelefono} className="mb-3" />
                <Input type={showPassword ? "text" : "password"} label="Password" size={"lg"} value={password} onValueChange={setPassword} iconRight={showPassword ? <MaterialSymbol icon="visibility_off" size={32} fill grade={-25} />  : <MaterialSymbol icon="visibility" size={32} fill grade={-25} />} onIconRightClick={() => setShowPassword(!showPassword)} className="mb-3"  />
                <Button className="mt-2.5 mb-3 w-full" color="primary" size="lg" onClick={register} disabled={loading}>{loading ? <CircularProgress color="white" aria-label="Loading..." /> : "Registrati"}</Button>
                <p>Hai già un account? <Link to="/login" className="text-blue-500 mt-2.5">Accedi</Link></p>
            </div>
        </div>
    );
}

export default RegistratiPage;