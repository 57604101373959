import React from 'react';
import { API_URL } from '../config';
import {Checkbox, Input, Dropdown,  DropdownTrigger,  DropdownMenu,  DropdownSection,  DropdownItem, Button, CircularProgress, Modal, Pagination, PaginationItem, PaginationCursor} from "@nextui-org/react";
import CardLibroOrizzontale from '../components/card/libroOrizzontale';
import ModalVetrina from "../components/books/modalVetrina";
import { MaterialSymbol } from 'react-material-symbols';

const LibriPage = () => {
    const [libri, setLibri] = React.useState([]);
    const [pagineMassime, setPagineMassime] = React.useState(1);
    const [pagina, setPagina] = React.useState(1);
    const [cerca, setCerca] = React.useState("");
    const [soloVetrina, setSoloVetrina] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [libroCliccato, setLibroCliccato] = React.useState({});
    React.useEffect(() => {
        fetch(API_URL + 'getLibri/?ricerca=' + cerca + '&solovetrina=' + (soloVetrina ? '1' : '0')+ '&page=' + pagina)
            .then(response => response.json())
            .then(data => {setPagineMassime(data.pagine);setLibri(data.libri);});
    }, [cerca, soloVetrina, pagina]);
    return (
        <div className='mt-3.5 container'>
            <div className="w-full md:w-1/2 mx-auto"><Input type="text" label="Cerca un libro o un autore" size={"lg"} value={cerca} onValueChange={setCerca} isClearable startContent={<MaterialSymbol icon="search" size={20} fill grade={-25} />} /></div>
            <div className='*grid *grid-cols-1 *md:grid-cols-3 *gap-4 mt-4'>
                {/*<div className="col-span-1">
                    
                </div>*/}
                <div className="*col-span-1 *md:col-span-2">
                    {/*<Dropdown>
                        <DropdownTrigger>
                        <Button variant="bordered">Ordina per</Button>
                        </DropdownTrigger>
                        <DropdownMenu>
                            <DropdownSection>
                                <DropdownItem>Titolo: crescente</DropdownItem>
                                <DropdownItem>Titolo: decrescente</DropdownItem>
                                <DropdownItem>Autore: crescente</DropdownItem>
                                <DropdownItem>Autore: decrescente</DropdownItem>
                                <DropdownItem>Casa editrice: crescente</DropdownItem>
                                <DropdownItem>Casa editrice: decrescente</DropdownItem>
                                <DropdownItem>Prezzo: crescente</DropdownItem>
                                <DropdownItem>Prezzo: decrescente</DropdownItem>
                                <DropdownItem>Disponibilità: crescente</DropdownItem>
                                <DropdownItem>Disponibilità: decrescente</DropdownItem>
                                <DropdownItem>Materia: crescente</DropdownItem>
                                <DropdownItem>Materia: decrescente</DropdownItem>
                            </DropdownSection>
                        </DropdownMenu>
                    </Dropdown>*/}
                    <Checkbox className="*ml-2" size="sm" isSelected={soloVetrina} onValueChange={setSoloVetrina}>Solo libri in vetrina</Checkbox>
                    <hr className='mt-2.5' />
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-3 mt-2.5'>
                        {libri.length === 0 && <div className="col-span-1 md:col-span-2 text-center"><CircularProgress color="primary" aria-label="Loading..."/></div>}
                        {libri.map((libro) => (
                            <CardLibroOrizzontale key={libro.id} libro={libro} isOpen={isOpen} setIsOpen={setIsOpen} setLibroCliccato={setLibroCliccato} />
                        ))}
                        {pagineMassime > 1 && <div className="col-span-1 md:col-span-2"><Pagination total={pagineMassime} page={pagina} onChange={(page) => setPagina(page)} /></div>}
                    </div>
                    {/*<div className='flex flex-col gap-4 mt-2.5'>
                        {libri.length === 0 && <CircularProgress color="primary" aria-label="Loading..."/>}
                        {libri.map((libro) => (
                            <CardLibroOrizzontale key={libro.id} libro={libro} isOpen={isOpen} setIsOpen={setIsOpen} setLibroCliccato={setLibroCliccato} />
                        ))}
                        {pagineMassime > 1 && <Pagination total={pagineMassime} page={pagina} onChange={(page) => setPagina(page)} />}
                    </div>*/}
                </div>
            </div>
            <Modal isOpen={isOpen} onOpenChange={() => setIsOpen(!isOpen)} classNames={{"base":"mx-0 my-0 rounded-none rounded-t-lg md:rounded-b-lg"}}>
                {libroCliccato && <ModalVetrina libro={libroCliccato} setIsOpen={setIsOpen} />}
            </Modal>
        </div>
    );
}
export default LibriPage;