import React from "react";
import { API_URL } from "../config";
import { Checkbox, Input, Dropdown, DropdownTrigger, DropdownMenu, DropdownSection, DropdownItem, Button, CircularProgress } from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";
import { MaterialSymbol } from 'react-material-symbols';
import { useAccount } from "../contexts/AccountContext";

const CambiaPasswordPage = () => {
    const [password, setPassword] = React.useState("");
    const [confermaPassword, setConfermaPassword] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfermaPassword, setShowConfermaPassword] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");

    const ModificaPassword = () => {
        if((password!=confermaPassword)&&password!=""){
            setError("Le password non corrispondono");
            return;
        }
        setLoading(true);
        var data=new FormData();
        data.append('password', password);
        fetch(API_URL + 'cambiaPassword', {
            method: 'POST',
            body: data,
            credentials: 'include',
            redirect: 'follow',
            headers: {
                'Accept': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.cambiata) {
                    setSuccess("Password cambiata con successo");
                    setLoading(false);
                    setError("");
                } else {
                    setError("Errore generico durante il cambio password");
                    setLoading(false);
                    setSuccess("");
                }
            });
    }

    return (
        <div className="container mt-3.5">
            <div className="w-full md:w-1/2 mx-auto">
                <h1 className="text-3xl font-bold mt-4 mb-4">Modifica password</h1>
                {success && <p className="bg-green-500 text-white py-2 px-3 mb-2.5 rounded shadow">{success}</p>}
                {error && <p className="bg-red-500 text-white py-2 px-3 mb-2.5 rounded shadow">{error}</p>}
                <Input type={showPassword ? "text" : "password"} label="Password" size={"lg"} value={password} onValueChange={setPassword} iconRight={showPassword ? <MaterialSymbol icon="visibility_off" size={32} fill grade={-25} />  : <MaterialSymbol icon="visibility" size={32} fill grade={-25} />} onIconRightClick={() => setShowPassword(!showPassword)} className="mb-3"  />
                <Input type={showConfermaPassword ? "text" : "password"} label="Conferma password" size={"lg"} value={confermaPassword} onValueChange={setConfermaPassword} iconRight={showConfermaPassword ? <MaterialSymbol icon="visibility_off" size={32} fill grade={-25} />  : <MaterialSymbol icon="visibility" size={32} fill grade={-25} />} onIconRightClick={() => setShowConfermaPassword(!showConfermaPassword)} className="mb-3"  />
                <Button className="mt-2.5 mb-3 w-full" color="primary" size="lg" onClick={ModificaPassword} disabled={loading}>{loading ? <CircularProgress color="white" aria-label="Loading..." /> : "Recupera password"}</Button>
            </div>
        </div>
    );
}

export default CambiaPasswordPage;