import React, { useState, useEffect } from "react";
import { API_URL } from "../config";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Input, Table, TableHeader, TableBody, TableColumn, TableRow, TableCell } from "@nextui-org/react";

const AdozioniPage = () => {
    const [provinces, setProvinces] = useState([]);
    const [selectedProvince, setSelectedProvince] = useState("");
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState("");
    const [schools, setSchools] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState("");
    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState("");
    const [adozioni, setAdozioni] = useState([]);

    useEffect(() => {
        // Fetch provinces data from API
        fetch(`${API_URL}/getClassi`)
            .then((response) => response.json())
            .then((data) => setProvinces(data.province))
            .catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        // Fetch cities data based on selected province from API
        if (selectedProvince) {
            fetch(`${API_URL}/getClassi?provincia=${selectedProvince}`)
                .then((response) => response.json())
                .then((data) => setCities(data.comuni))
                .catch((error) => console.log(error));
        }
    }, [selectedProvince]);

    useEffect(() => {
        // Fetch schools data based on selected city from API
        if (selectedCity) {
            fetch(`${API_URL}/getClassi?comune=${selectedCity}`)
                .then((response) => response.json())
                .then((data) => setSchools(data.scuole))
                .catch((error) => console.log(error));
        }
    }, [selectedCity]);

    useEffect(() => {
        // Fetch classes data based on selected school from API
        if (selectedSchool) {
            fetch(`${API_URL}/getClassi?codice_meccanografico=${selectedSchool.codice_meccanografico}`)
                .then((response) => response.json())
                .then((data) => setClasses(data.classi))
                .catch((error) => console.log(error));
        }
    }, [selectedSchool]);

    useEffect(() => {
        if (selectedClass) {
            var classe=selectedClass.split(" ")[0];
            var sezione=selectedClass.split(" ")[1].split(" - ")[0];
            fetch(`${API_URL}/adozioniscuole?codice_meccanografico=${selectedSchool.codice_meccanografico}&classe=${classe}&sezione=${sezione}`)
                .then((response) => response.json())
                .then((data) => setAdozioni(data.libri))
                .catch((error) => console.log(error));
        }
    }, [selectedClass]);

    const handleProvinceChange = (event) => {
        setSelectedProvince(event.target.value);
        setSelectedCity("");
        setSelectedSchool("");
        setSelectedClass("");
        setCities([]);
        setSchools([]);
        setClasses([]);
        setAdozioni([]);
    };

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
        setSelectedSchool("");
        setSelectedClass("");
        setSchools([]);
        setClasses([]);
        setAdozioni([]);
    };

    const handleSchoolChange = (event) => {
        setSelectedSchool(event.target.value);
        setSelectedClass("");
        setClasses([]);
        setAdozioni([]);
    };

    const handleClassChange = (event) => {
        setSelectedClass(event.target.value);
        setAdozioni([]);
    };

    return (
        <div className="container mt-2">
            <h1 className="text-2xl font-bold mb-2">Adozioni</h1>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-5">
                <div class="md:col-span-1">
                    <div class="md:sticky md:top-[6rem] space-y-3">
                        <Dropdown type="listbox" selectionMode="single" selectedKeys={selectedProvince}>
                            <DropdownTrigger>
                                <Input
                                    isReadOnly
                                    type="text"
                                    label="Provincia"
                                    variant="bordered"
                                    value={selectedProvince}
                                    className="w-full"
                                />
                            </DropdownTrigger>
                            <DropdownMenu variant="flat" classNames={{"list": "max-h-[45vh] overflow-y-auto"}}>
                                {provinces.map((provincia) => (
                                    <DropdownItem key={provincia} onClick={() => setSelectedProvince(provincia)}>
                                        {provincia}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown type="listbox" selectionMode="single" selectedKeys={selectedCity}>
                            <DropdownTrigger>
                                <Input
                                    isReadOnly
                                    isDisabled={!selectedProvince}
                                    type="text"
                                    label="Città"
                                    variant="bordered"
                                    value={selectedCity}
                                    className="w-full text-left"
                                />
                            </DropdownTrigger>
                            <DropdownMenu variant="flat" classNames={{"list": "max-h-[45vh] overflow-y-auto"}}>
                                {cities.map((citta) => (
                                    <DropdownItem key={citta} onClick={() => setSelectedCity(citta)}>
                                        {citta}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown type="listbox"  selectionMode="single" selectedKeys={selectedSchool.codice_meccanografico}>
                            <DropdownTrigger>
                                <Input
                                    isReadOnly
                                    isDisabled={!selectedCity}
                                    type="text"
                                    label="Scuola"
                                    variant="bordered"
                                    value={selectedSchool.denominazione}
                                    className="w-full text-left"
                                />
                            </DropdownTrigger>
                            <DropdownMenu variant="flat" classNames={{"list": "max-h-[45vh] overflow-y-auto"}}>
                                {schools.map((scuola) => (
                                    <DropdownItem key={scuola.codice_meccanografico} onClick={() => setSelectedSchool(scuola)}>
                                        {scuola.denominazione}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown type="listbox" selectionMode="single" selectedKeys={selectedClass}>
                            <DropdownTrigger>
                                <Input
                                    isReadOnly
                                    isDisabled={!selectedSchool}
                                    type="text"
                                    label="Classe"
                                    variant="bordered"
                                    value={selectedClass}
                                    className="w-full text-left"
                                />
                            </DropdownTrigger>
                            <DropdownMenu variant="flat" classNames={{"list": "max-h-[45vh] overflow-y-auto"}}>
                                {classes.map((classe) => (
                                    <DropdownItem key={classe} onClick={() => setSelectedClass(classe)}>
                                        {classe}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
            </div>
            <div class="md:col-span-2">
                {adozioni.length > 0 && (
                    <Table isHeaderSticky={true} shadow={false} classNames={{"table": "p-0"}}>
                        <TableHeader>
                            <TableColumn>Materia</TableColumn>
                            <TableColumn>ISBN</TableColumn>
                            <TableColumn>Autore</TableColumn>
                            <TableColumn>Titolo</TableColumn>
                            <TableColumn>Volume</TableColumn>
                            <TableColumn>Editore</TableColumn>
                            <TableColumn>Prezzo</TableColumn>
                            <TableColumn>Nuova adozione</TableColumn>
                            <TableColumn>Da acquistare</TableColumn>
                            <TableColumn>Consigliato</TableColumn>
                            <TableColumn>Presente in vetrina</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {adozioni.map((adozione) => (
                                <TableRow key={adozione.isbn}>
                                    <TableCell>{adozione.materia}</TableCell>
                                    <TableCell>{adozione.isbn}</TableCell>
                                    <TableCell>{adozione.autore}</TableCell>
                                    <TableCell>{adozione.titolo}</TableCell>
                                    <TableCell>{adozione.volume}</TableCell>
                                    <TableCell>{adozione.casa_editrice}</TableCell>
                                    <TableCell>{adozione.prezzo}</TableCell>
                                    <TableCell>{adozione.nuova_adozione ? 'Si' : 'No'}</TableCell>
                                    <TableCell>{adozione.da_acquistare ? 'Si' : 'No'}</TableCell>
                                    <TableCell>{adozione.consigliato ? 'Si' : 'No'}</TableCell>
                                    <TableCell>{adozione.presente_in_vetrina ? 'Si' : 'No'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </div>
        </div>
        </div>
    );
};

export default AdozioniPage;