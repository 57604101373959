import { Image } from "@nextui-org/react";

const Homepage = () => {
    return (
        <div>
            <Image class="hidden md:block" src="https://cdn.mercatinoudsbari.it/banner-desktop.png" className="w-full" removeWrapper={true} />
            <Image class="block md:hidden" src="https://cdn.mercatinoudsbari.it/banner-mobile.png" className="w-full" removeWrapper={true} />
        </div>
    );
}
export default Homepage;