import React from "react";
import { API_URL } from "../config";
import { useAccount } from "../contexts/AccountContext";
import { MaterialSymbol } from "react-material-symbols";
import {Accordion, AccordionItem,Listbox, ListboxItem} from "@nextui-org/react";
import CardLibroOrizzontale from "../components/card/libroOrizzontale";
import { useNavigate } from "react-router-dom";

const ProfiloPage = () => {
    const { account } = useAccount();
    const navigate = useNavigate();
    const [result, setResult] = React.useState({"libri_acquistati":[],"libri_venduti":[],"libri_in_vendita":[]});
    React.useEffect(() => {
        var requestOptions = {
            credentials: 'include',
            method: 'GET',
            redirect: 'follow'
        };
        fetch(API_URL + 'accountBooks/', requestOptions)
            .then(response => response.json())
            .then(data => setResult(data));
    }, []);
    const logout = () => {
        var requestOptions = {
            credentials: 'include',
            method: 'POST',
            redirect: 'follow'
        };
        fetch(API_URL + 'logout/', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(!data.loggato) {
                    window.location.href = "/";
                }
            });
    }

    const cancellaAccount = () => {
        window.confirm("Sei sicuro di voler cancellare il tuo account?") && fetch(API_URL + 'cancellaAccount/', {
            method: 'POST',
            credentials: 'include',
            redirect: 'follow',
            headers: {
                'Accept': 'application/json'
            },
        })
        .then(response => response.json())
        .then(data => {
            if (data.cancellato) {
                window.location.href = "/";
            }
        });
    }

    return (
        <div className="container mx-auto py-2">
            {account.utente && (
            <div class="flex *justify-center items-center gap-x-3.5 mt-3">
                <MaterialSymbol icon="account_circle" size={48} fill grade={-25} />
                <div class="flex flex-col">
                    <p class="text-lg font-bold">{account.utente.nome} {account.utente.cognome}</p>
                    <p class="text-sm font-medium">{account.utente.email}</p>
                </div>
            </div>
            )}
            {account.utente && (
                <Listbox variant="flat" aria-label="Listbox menu with descriptions" classNames={{"base":"-mx-2 mt-2 mb-2"}}>
                {(account.utente.ruolo === "admin" || account.utente.ruolo === "operatore") && (
                <ListboxItem
                    onClick={() => window.location.href="/gestionale/index.html"}
                  >Accedi nel gestionale</ListboxItem>)}
                <ListboxItem
                  key="copy"
                  description={account.utente.telefono}
                >Telefono</ListboxItem>
                <ListboxItem
                  key="cut"
                  description={account.utente.id}
                >Tessera</ListboxItem>
                <ListboxItem
                  key="cut"
                  className={account.utente.donazione ? "text-success" : "text-danger"}
                >Donazione {account.utente.donazione ? "effettuata" : "non effettuata"}</ListboxItem>
                <ListboxItem
                    onClick={() => navigate("/modifica-password")}
                >Cambia password</ListboxItem>
                <ListboxItem
                  className="text-danger"
                  color="danger"
                  onClick={cancellaAccount}
                >Cancella account</ListboxItem>
                <ListboxItem
                  className="text-danger"
                  color="danger"
                  onClick={logout}
                >Logout</ListboxItem>
              </Listbox>
            )}
            <div className="-mx-2">
                <Accordion selectionMode="multiple">
                    {result.libri_acquistati.length > 0 && 
                    <AccordionItem key="1" aria-label="Accordion 1" title="Libri da te acquistati">
                        <div className='flex flex-col gap-4 mt-2.5'>
                        {result.libri_acquistati.map((libro) => (
                            <CardLibroOrizzontale key={libro.id} libro={libro} />
                        ))}
                        </div>
                    </AccordionItem>
                    }
                    {result.libri_venduti.length > 0 &&
                    <AccordionItem key="2" aria-label="Accordion 2" title="Libri da te venduti">
                        <div className='flex flex-col gap-4 mt-2.5'>
                        {result.libri_venduti.map((libro) => (
                            <CardLibroOrizzontale key={libro.id} libro={libro} />
                        ))}
                        </div>
                    </AccordionItem>
                    }
                    {result.libri_in_vendita.length > 0 &&
                    <AccordionItem key="3" aria-label="Accordion 3" title="Tuoi libri in vendita">
                        <div className='flex flex-col gap-4 mt-2.5'>
                        {result.libri_in_vendita.map((libro) => (
                            <CardLibroOrizzontale key={libro.id} libro={libro} />
                        ))}
                        </div>
                    </AccordionItem>
                    }
                </Accordion>
            </div>
        </div>
    );
}

export default ProfiloPage;