import React, { useEffect } from "react";
import { API_URL } from "../config";
import { Checkbox, Input, Dropdown, DropdownTrigger, DropdownMenu, DropdownSection, DropdownItem, Button, CircularProgress } from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";
import { MaterialSymbol } from 'react-material-symbols';
import { useAccount } from "../contexts/AccountContext";

const RecuperoPasswordPage = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");

    useEffect(() => {
        if(queryParameters.get("idutente")==null||queryParameters.get("idutente")==""||queryParameters.get("token")==null||queryParameters.get("token")=="") {
            return;
        }
        var data=new FormData();
        data.append('idutente', queryParameters.get("idutente"));
        data.append('token', queryParameters.get("token"));
        fetch(API_URL + 'verificaRecuperoPassword', {
            method: 'POST',
            body: data,
            credentials: 'include',
            redirect: 'follow',
            headers: {
                'Accept': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => {
                if(data.changed) {
                    if(data.message=="EMAIL_SENT") {
                        setSuccess("La tua password è stata cambiata con successo. Ora puoi accedere al tuo account con la nuova password che ti è stata inviata via mail.");
                        setError("");
                    } else if(data.message=="EMAIL_NOT_SENT") {
                        setError("La tua password è stata cambiata con successo, ma non è stato possibile inviarti la nuova password via mail. Contatta l'amministratore.");
                        setSuccess("");
                    }
                } else {
                    if(data.message=="TOKEN_EXPIRED") {
                        setError("Il token di recupero password è scaduto. Richiedi un nuovo link per recuperare la password.");
                        setSuccess("");
                    } else if(data.message=="TOKEN_NOT_VALID") {
                        setError("Il token di recupero password non è valido. Richiedi un nuovo link per recuperare la password.");
                        setSuccess("");
                    } else if(data.message=="USER_NOT_FOUND") {
                        setError("Utente non trovato. Richiedi un nuovo link per recuperare la password.");
                        setSuccess("");
                    } else if(data.message=="ERROR") {
                        setError("Errore interno. Riprova più tardi.");
                        setSuccess("");
                    } else {
                        setError("Errore generico. Riprova più tardi.");
                        setSuccess("");
                    }
                }
            });
    }, []);

    if(queryParameters.get("idutente")==null||queryParameters.get("idutente")==""||queryParameters.get("token")==null||queryParameters.get("token")=="") {
        return (
            <div className="container mt-3.5">
                <div className="w-full md:w-1/2 mx-auto">
                    <h1 className="text-3xl font-bold mt-4 mb-4">Recupero password</h1>
                    <p className="bg-red-500 text-white py-2 px-3 mb-2.5 rounded shadow">Richiesta non valida</p>
                </div>
            </div>
        );
    }

    return (
        <div className="container mt-3.5">
            <div className="w-full md:w-1/2 mx-auto">
                <h1 className="text-3xl font-bold mt-4 mb-4">Recupero password</h1>
                {success && <p className="bg-green-500 text-white py-2 px-3 mb-2.5 rounded shadow">{success}</p>}
                {error && <p className="bg-red-500 text-white py-2 px-3 mb-2.5 rounded shadow">{error}</p>}
            </div>
        </div>
    );
}

export default RecuperoPasswordPage;