import React, { createContext, useContext, useState, useEffect } from 'react';
import { API_URL } from '../config';

const AccountContext = createContext();

export const useAccount = () => {
  return useContext(AccountContext);
};

export const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState({loggato: false, utente: null});

  const ChangeLogged = () => {
    aggiornaAccount();
    return null;
  };

  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        var requestOptions = {
            credentials: 'include',
            method: 'GET',
            redirect: 'follow'
          };
        const response = await fetch(`${API_URL}account/`, requestOptions);
        const accountData = await response.json();
        setAccount(accountData);
      } catch (error) {
        console.error('Errore nel recupero delle informazioni dell\'account', error);
      }
    };

    fetchAccountInfo();
  }, []);

 const aggiornaAccount = () => {
    const fetchAccountInfo = async () => {
      try {
        var requestOptions = {
            credentials: 'include',
            method: 'GET',
            redirect: 'follow'
          };
        const response = await fetch(`${API_URL}account/`, requestOptions);
        const accountData = await response.json();
        setAccount(accountData);
      } catch (error) {
        console.error('Errore nel recupero delle informazioni dell\'account', error);
      }
    };
    setTimeout(() => {
      fetchAccountInfo();
    }, 1000);
  };

  const value = {
    account
  };

  return (
    <AccountContext.Provider value={{account, ChangeLogged}}>
      {children}
    </AccountContext.Provider>
  );
};